.footer-section {
  background-color: $darkShadeColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .footer-content-wrapper {
    margin: auto;
    width: 80%;
    padding: 3rem 0;

    @media screen and (max-width: 500px) {
      width: 90%;
    }

    .footer-head-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
      }

      img {
        @media screen and (max-width: 800px) {
          width: 100px;
        }
      }

      .newsletter-form-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
        font-family: "Montserrat", sans-serif;

        input {
          font-family: inherit;
          width: 250px;
          padding: 1rem 0.5rem;
          padding-left: 1rem;
          outline: none;
          border-top-left-radius: 2rem;
          border-bottom-left-radius: 2rem;
          border: none;
          font-size: 0.8rem;
          font-weight: 500;

          @media screen and (max-width: 800px) {
            width: 150px;
          }

          @media screen and (max-width: 500px) {
            width: 200px;
            font-size: 0.6rem;
          }

          @media screen and (max-width: 320px) {
            width: 150px;
          }
        }

        .subscribe-button {
          padding: 1rem 1.5rem;
          font-family: inherit;
          border-top-right-radius: 2rem;
          border-bottom-right-radius: 2rem;
          border: none;
          outline: none;
          background-color: $secondaryThemeColor;
          text-transform: uppercase;
          color: $whiteColor;
          font-size: 0.8rem;
          font-weight: 600;
          cursor: pointer;

          @media screen and (max-width: 800px) {
            padding: 1rem;
          }

          @media screen and (max-width: 500px) {
            font-size: 0.6rem;
            padding: 1rem 1rem;
          }
        }

        .subscribe-button-disabled {
          background-color: $lightGray;
          cursor: not-allowed;
        }
      }

      .social-links-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        .icon-container {
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $secondaryThemeColor;
          border-radius: 50%;
        }
      }
    }

    .footer-body-wrapper {
      display: flex;
      margin-top: 4rem;
      justify-content: space-between;
      gap: 3rem;

      @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
      }

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      .body-column-wrapper {
        min-width: 20%;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media screen and (max-width: 1024px) {
          width: 45%;
        }

        @media screen and (max-width: 500px) {
          width: 100%;
          max-width: 100%;
        }
        .title {
          font-size: 1rem;
          font-weight: 600;
          position: relative;
          width: max-content;
          padding-bottom: 1rem;
          text-transform: uppercase;

          &:before {
            content: "";
            position: absolute;
            width: 50%;
            height: 10px;
            bottom: 0;
            border-bottom: 2px solid $secondaryThemeColor;
          }
        }

        .description {
          font-size: 0.9rem;
          line-height: 170%;
          width: 90%;
          margin-top: 0.5rem;

          @media screen and (max-width: 500px) {
            width: 100%;
          }
        }

        .details-wrapper {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .about-detail {
            display: flex;
            align-items: center;
            gap: 1rem;
            white-space: nowrap;

            a {
              text-decoration: none;
              color: inherit;
            }
            @media screen and (max-width: 500px) {
              font-size: 0.9rem;
            }

            .icon-wrapper {
              background-color: $secondaryThemeColor;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              aspect-ratio: 1/1;

              svg {
                path {
                  stroke: $whiteColor;
                }
              }
            }
          }

          .link-wrapper {
            display: flex;
            white-space: nowrap;
            gap: 1rem;
            margin: 0.3rem 0;
            a {
              text-decoration: none;
              color: $textWhiteColor;
              transition: all 0.3s ease-in-out;

              &:hover {
                color: $secondaryThemeColor;
              }
            }
          }
        }

        .latest-posts-wrapper {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .latest-post {
            display: flex;
            gap: 1.5rem;
            align-items: center;
            justify-content: space-between;

            @media screen and (max-width: 500px) {
              font-size: 0.9rem;
            }

            img {
              width: 100px;
              height: 100%;
              object-fit: contain;
            }

            .post-detail {
              display: flex;
              flex-direction: column;
              gap: 0.6rem;

              .post-title {
                line-height: 170%;
                width: 80%;
              }

              .post-date {
                color: $secondaryThemeColor;
              }
            }
          }
        }

        // Schedule Column
        .schedule-wrapper {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .schedule {
            display: flex;
            justify-content: space-between;
            gap: 3rem;
            align-items: center;
            font-weight: 600;

            .time {
              color: $secondaryThemeColor;
              text-align: right;
            }
          }
        }
      }
    }
  }

  .sub-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0;
    background-color: #202b37;
    width: 100%;
    font-size: 1rem;

    @media screen and (max-width: 500px) {
      p {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      font-size: 0.8rem;
    }
  }
}
