.map-section {
  height: 100%;
  height: 70vh;
  margin: auto;

  @media screen and (min-width: 1000px) {
    filter: grayscale(0.6);
  }

  &:hover {
    filter: grayscale(0);
  }

  .mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: inherit;
  }
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: inherit;
  }
  .gmap_iframe {
    height: inherit !important;
  }
}
