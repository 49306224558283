.contact-details-section {
  width: 80%;
  margin: auto;

  @media screen and (max-width: 500px) {
    width: 90%;
  }

  .contact-details-wrapper {
    width: 100%;
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-wrapper {
      width: 50%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;

      @media screen and (max-width: 1024px) {
        width: 85%;
      }

      @media screen and (max-width: 800px) {
        width: 80%;
      }

      @media screen and (max-width: 500px) {
        width: 90%;
      }

      .section-title {
        font-size: 2.2rem;
        text-align: center;
        width: 80%;

        @media screen and (max-width: 800px) {
        }

        @media screen and (max-width: 500px) {
          font-size: 2rem;
          width: 100%;
        }

        @media screen and (max-width: 400px) {
          font-size: 1.8rem;
          width: 100%;
        }

        @media screen and (max-width: 350px) {
          font-size: 1.5rem;
          margin: 0;
          width: 100%;
        }
      }

      .section-description {
        font-size: 1rem;
        line-height: 180%;
        text-align: center;

        @media screen and (max-width: 500px) {
          font-size: 0.8rem;
        }

        @media screen and (max-width: 350px) {
          font-size: 0.7rem;
        }
      }
    }

    .contact-detail-cards-wrapper {
      margin-top: 2rem;
      display: flex;
      align-items: stretch;
      justify-content: center;
      gap: 2rem;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 800px) {
        justify-content: center;
        flex-wrap: wrap;
      }

      .contact-detail-card {
        width: 24%;
        height: auto;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
        background-color: $textBlackColor;
        aspect-ratio: 2/1.5 !important;
        border-radius: 1.3rem;

        @media screen and (max-width: 1200px) {
          aspect-ratio: 1/1;
          width: 100%;
        }

        @media screen and (max-width: 800px) {
          width: 35%;
        }

        @media screen and (max-width: 500px) {
          width: 100%;
        }

        .icon-wrapper {
          background-color: $secondaryThemeColor;
          padding: 1rem;
          aspect-ratio: 1/1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 1.3rem;

          @media screen and (max-width: 1200px) {
            padding: 0.7rem;
            svg {
              width: 70%;
              height: 70%;
            }
          }
        }

        .card-title {
          font-size: 1.7rem;

          @media screen and (max-width: 1200px) {
            font-size: 1.3rem;
          }
        }

        .card-detail {
          white-space: wrap;
          text-decoration: none;
          color: $whiteColor;
          width: 80%;
          line-height: 170%;
          font-weight: 500;
          font-size: 0.8rem;

          @media screen and (max-width: 1200px) {
            width: 100%;
          }

          &:hover {
            color: $secondaryThemeColor;
          }
        }
      }
    }
  }
}
