.navbar-wrapper {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 110px;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 100000;

  @media screen and (max-width: 800px) {
    height: 100px;
  }

  .navbar-container {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @media screen and (max-width: 800px) {
      align-items: center;
    }

    @media screen and (max-width: 500px) {
      width: 90%;
    }

    .logo-container {
      padding: 1rem 0;
      @media screen and (max-width: 800px) {
        img {
          width: 70%;
        }
      }
    }

    .nav-items-wrapper {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 3rem;

      @media screen and (max-width: 1024px) {
        gap: 1.5rem;
      }

      @media screen and (max-width: 800px) {
        display: none;
      }
      a {
        height: 100%;
        padding: 0 0.3rem;
        padding-top: auto;
        padding-bottom: auto;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: 500;
        font-size: 1rem;
        color: $whiteColor;
        position: relative;
        justify-content: center;

        @media screen and (max-width: 1024px) {
          white-space: nowrap;

          font-size: 0.8rem;
        }
      }

      .selected-line {
        position: absolute;
        height: 5px;
        width: 110%;
        background-color: $primaryThemeColor;
        top: 0;
      }

      .hidden {
        visibility: hidden;
      }

      .active {
        color: $primaryThemeColor;
        font-style: italic;
      }

      button {
        margin: auto;
        padding: 1rem 1.5rem;
        font-weight: 500;
        font-size: 0.8rem;
        color: $whiteColor;
        background-color: transparent;
        border: 1px solid $whiteColor;
        font-family: inherit;
        border-radius: 0.7rem;

        &:hover {
          border-color: $primaryThemeColor;
          color: $primaryThemeColor;
          cursor: pointer;
        }

        @media screen and (max-width: 1024px) {
          white-space: nowrap;
          font-size: 0.8rem;
        }
      }
    }

    .sidebar-wrapper {
      display: none;

      // Sidebar Styles
      .bm-burger-button {
        position: relative;
        width: 50px;
        height: 50px;
        svg {
          path {
            fill: $secondaryThemeColor;
          }
        }
      }

      .bm-burger-bars {
        background: #373a47;
      }

      .bm-burger-bars-hover {
        background: #a90000;
      }

      .bm-cross-button {
        height: 54px;
        width: 54px;
        top: 30px !important;
        right: 40px !important;
      }

      .bm-cross {
        background: $secondaryThemeColor;
        width: 5px !important;
        height: 50px !important;
        border-radius: 1rem;

        @media screen and (max-width: 500px) {
          height: 30px !important;
        }
      }

      .bm-menu-wrap {
        position: fixed;
        height: 100%;
        top: 0;
        width: 70vw !important;

        @media screen and (max-width: 500px) {
          width: 90vw !important;
        }
      }

      .bm-menu {
        background: #0000003b;
        padding: 5em 1.5em 0;
        font-size: 1.15em;
        box-shadow: 0 0 4px $lightGray;
        backdrop-filter: blur(15px);
      }

      .bm-morph-shape {
        fill: #373a47;
      }

      .bm-item-list {
        color: #b8b7ad;
        padding: 0.8em;
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }

      .bm-item {
        display: inline-block;
        text-align: left;
        color: $whiteColor;
        font-weight: 500;
        text-decoration: none;
        font-size: 2.5rem;
        font-family: inherit;

        transition: all 0.5s ease;

        &:hover {
          color: $secondaryThemeColor;
          transform: translateX(1rem);
        }

        @media screen and (max-width: 500px) {
          font-size: 1.5rem;
        }
      }

      .active {
        color: $secondaryThemeColor !important;
        border-color: $secondaryThemeColor !important;
      }

      .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
      }

      .query-action-button {
        padding: 1rem 1.5rem;
        font-weight: 500;
        font-size: 1.6rem;
        color: $whiteColor;
        background-color: transparent;
        border: 2px solid $whiteColor;
        font-family: inherit;
        border-radius: 0.7rem;

        &:hover {
          border-color: $primaryThemeColor;
          color: $primaryThemeColor;
          cursor: pointer;
        }

        @media screen and (max-width: 500px) {
          font-size: 1rem;
        }
      }
      .focused {
        border-color: $primaryThemeColor;
        color: $primaryThemeColor;
      }
      @media screen and (max-width: 800px) {
        display: flex;
      }
    }
  }
}
