* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $darkShadeColor;
}

.App {
  color: $textWhiteColor;
  position: relative;
}

.content-wrapper {
  padding: 3rem 0;
  width: 80%;

  @media screen and (max-width: 500px) {
    width: 90%;
  }

  .title-wrapper {
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    @media screen and (max-width: 1024px) {
      width: 80%;
    }

    @media screen and (max-width: 800px) {
      width: 80%;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }

    .section-title {
      font-size: 2.2rem;

      @media screen and (max-width: 800px) {
      }

      @media screen and (max-width: 500px) {
        font-size: 2rem;
      }

      @media screen and (max-width: 400px) {
        font-size: 1.8rem;
      }

      @media screen and (max-width: 350px) {
        font-size: 1.5rem;
        margin: 0;
      }
    }

    .section-description {
      font-size: 1rem;
      line-height: 180%;
      text-align: center;

      @media screen and (max-width: 500px) {
        font-size: 0.8rem;
      }

      @media screen and (max-width: 350px) {
        font-size: 0.7rem;
      }
    }
  }
}
