.about-description-section {
  width: 80%;
  margin: auto;
  height: auto;
  background-color: $darkShadeColor;

  @media screen and (max-width: 500px) {
    width: 90%;
  }

  .about-description-wrapper {
    padding: 3rem 0;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @media screen and (max-width: 1200px) {
      padding: 4rem 0;
      gap: 2rem;
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }

    .description-wrapper {
      padding: 3rem 0;
      position: relative;
      width: 50%;

      @media screen and (max-width: 1200px) {
        padding: 2rem 0;
      }

      @media screen and (max-width: 800px) {
        padding: 0;
        width: 100%;
      }

      .background-text-container {
        position: relative;
        h2 {
          color: transparent;
          font-size: 150px;
          -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
          -webkit-text-stroke: 0.4px #d6d6d65b;

          @media screen and (max-width: 1200px) {
            font-size: 100px;
          }

          @media screen and (max-width: 800px) {
            font-size: 75px;
          }

          @media screen and (max-width: 350px) {
            font-size: 65px;
          }
        }

        .about-content-wrapper {
          height: 100%;
          position: absolute;
          top: 2rem;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          left: 1rem;
          display: flex;
          flex-direction: column;
          gap: 0.7rem;

          @media screen and (max-width: 1200px) {
            top: 0.7rem;
          }

          @media screen and (max-width: 800px) {
            top: 0;
            gap: 0.5rem;
          }

          @media screen and (max-width: 500px) {
            width: 90%;
          }

          .test {
            position: relative;
            z-index: 2;
            top: 3rem;
            display: flex;
            gap: 0.5rem;
            flex-direction: column;

            h5 {
              color: $primaryThemeColor;
              font-weight: 600;
              font-size: 1rem;
              border-left: 2px solid $primaryThemeColor;
              padding-left: 0.5rem;

              @media screen and (max-width: 1200px) {
                font-size: 0.8rem;
              }
            }
            h4 {
              color: $whiteColor;
              font-size: 2.3rem;
              line-height: 150%;

              @media screen and (max-width: 1200px) {
                font-size: 1.5rem;
              }

              @media screen and (max-width: 800px) {
                font-size: 1.1rem;
              }
            }

            p {
              font-size: 1rem;
              line-height: 170%;

              @media screen and (max-width: 1200px) {
                font-size: 0.8rem;
              }

              @media screen and (max-width: 1200px) {
                font-size: 0.7rem;
                line-height: 150%;
              }

              @media screen and (max-width: 1000px) {
                height: 120px;
                overflow-y: scroll;
                padding-right: 10px;
              }

              @media screen and (max-width: 600px) {
                height: auto;
                overflow: unset;
                padding: 0;
              }
            }
            button {
              margin-top: 1rem;
              color: $darkShadeColor;
              background-color: $whiteColor;
              border: none;
              border-radius: 0.5rem;
              padding: 1rem 1.5rem;
              font-family: inherit;
              width: fit-content;
              font-weight: 600;
              font-size: 1.1rem;

              @media screen and (max-width: 1200px) {
                font-size: 0.8rem;
                padding: 0.7rem 1rem;
              }

              @media screen and (max-width: 1200px) {
                margin-top: 0rem;
              }

              @media screen and (max-width: 350px) {
                margin-top: 1rem;
              }
            }
          }
        }
      }
    }

    .image-wrapper {
      position: relative;

      @media screen and (max-width: 500px) {
        margin-top: 15rem;
      }

      img {
        width: 100%;
      }
    }
  }
}
