.maintenance-service-section {
  width: 80%;
  margin: auto;

  @media screen and (max-width: 500px) {
    width: 90%;
  }

  .maintenance-service-wrapper {
    padding: 4rem 0;

    .title-wrapper {
      width: 50%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;

      @media screen and (max-width: 1024px) {
        width: 85%;
      }

      @media screen and (max-width: 800px) {
        width: 80%;
      }

      @media screen and (max-width: 500px) {
        width: 90%;
      }
    }

    .section-title {
      font-size: 2.2rem;
      text-align: center;

      @media screen and (max-width: 800px) {
      }

      @media screen and (max-width: 500px) {
        font-size: 2rem;
      }

      @media screen and (max-width: 400px) {
        font-size: 1.8rem;
      }

      @media screen and (max-width: 350px) {
        font-size: 1.5rem;
        margin: 0;
      }
    }

    .section-description {
      font-size: 1rem;
      line-height: 180%;
      text-align: center;

      @media screen and (max-width: 500px) {
        font-size: 0.8rem;
      }

      @media screen and (max-width: 350px) {
        font-size: 0.7rem;
      }
    }

    .download-button-wrapper {
      margin-top: 2rem;
      display: flex;
      justify-content: space-around;
      width: 70%;

      img {
        width: 200px;
      }

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }
    }

    .services-grid-container {
      .grid-wrapper {
        margin-top: 3rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 10px;
        grid-template-areas:
          "card1 card2 card3"
          "card1 card4 card5"
          "card6 card7 card10"
          "card8 card9 card10";

        $grid-items: 10;

        @for $i from 1 through $grid-items {
          .card#{$i} {
            grid-area: card#{$i};
          }
        }

        @media screen and (max-width: 500px) {
          grid-template-columns: auto auto;
          grid-template-areas:
            "card1 card2"
            "card3 card4"
            "card5 card6"
            "card7 card8"
            "card9 card10";
          grid-template-rows: auto;
        }

        .service-info-card {
          position: relative;
          .service-image {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media screen and (max-width: 500px) {
              aspect-ratio: 1/1;
            }
          }

          &:nth-child(1) {
            .service-card-overlay {
              height: 15%;
              @media screen and (max-width: 500px) {
                height: 100%;
              }
            }
          }

          &:nth-child(10) {
            .service-card-overlay {
              height: 15%;
              @media screen and (max-width: 500px) {
                height: 100%;
              }
            }
          }

          .service-card-overlay {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(
              180deg,
              transparent,
              $darkShadeColor 100%
            );
            overflow: hidden;
            width: 100%;
            height: 30%;
            transition: 0.5s ease;

            @media screen and (max-width: 500px) {
              height: 100%;
              background: $darkOverlayColor;
            }

            .overlay-details-wrapper {
              width: 100%;
              padding: 2rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 1rem;
              height: 100%;
              align-items: center;

              @media screen and (max-width: 1024px) {
                gap: 0.5rem;
                padding: 1rem;
              }

              .title {
                font-size: 1.4rem;

                @media screen and (max-width: 1024px) {
                  font-size: 1.2rem;
                }

                @media screen and (max-width: 800px) {
                  font-size: 1rem;
                }

                @media screen and (max-width: 500px) {
                  font-size: 0.7rem;
                }
              }

              .description {
                font-size: 1rem;
                font-weight: 400;
                line-height: 170%;

                @media screen and (max-width: 1024px) {
                  font-size: 0.7rem;
                }

                @media screen and (max-width: 800px) {
                  display: none !important;
                }

                @media screen and (max-width: 500px) {
                  display: none !important;
                }
              }

              .more-info-button {
                color: $whiteColor;
                background-color: $secondaryThemeColor;
                border: none;
                padding: 1rem;
                font-weight: 500;
                border-radius: 5px;
                font-family: "Montserrat", sans-serif;
                cursor: pointer;

                @media screen and (max-width: 1024px) {
                  font-size: 0.7rem;
                  padding: 0.7rem;
                }

                @media screen and (max-width: 800px) {
                  display: none !important;
                }

                @media screen and (max-width: 500px) {
                  display: flex !important;
                }
              }

              .description,
              .more-info-button {
                display: none;
              }
            }
          }

          @media screen and (min-width: 700px) {
            &:hover {
              .service-card-overlay {
                height: 100%;
                background: $darkOverlayColor;
                display: flex;
                align-items: flex-end;

                .description,
                .more-info-button {
                  display: flex !important;
                }

                .description {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
