$primaryThemeColor: #edc146;
$secondaryThemeColor: #b97d6d;
$darkShadeColor: #131313;
$textWhiteColor: #e4e4e4;
$darkOverlayColor: #00000096;
$whiteColor: #ffffff;
$overlayColor: #0a0a0ae6;
$textBlackColor: #525252;
$lightGray: #e2e2e2;
$fieldBorder: #cccccc;
